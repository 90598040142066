import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/vsts/work/1/s/src/layouts/mdlayout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEOPage = makeShortcode("SEOPage");
const BenefitsHeadingPara = makeShortcode("BenefitsHeadingPara");
const InformationCard = makeShortcode("InformationCard");
const BenefitsHeadingButton = makeShortcode("BenefitsHeadingButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOPage title="Benefits for Teachers" description="Explore how Dynos is beneficial for teachers" image="mathlesson" mdxType="SEOPage" />
    <BenefitsHeadingPara heading="Powerful tools for joyful teaching" subHeading="Dynos helps teachers build interactive lessons, quickly assess students, and much more." button="Login" image="/teachers.png" className="teachers" mdxType="BenefitsHeadingPara" />
    <InformationCard name="expectancy" question="How likely does the user think they will be successful using the product? What resources do you have?" answer1="It is very time-consuming for teachers to prepare their own digital content and lesson plans." answer2="Dynos provides ready-made comprehensive digital curriculum along with answer keys and teacher manuals, on-boarding videos as well as training workshops make teachers comfortable using Dynos and save time." mdxType="InformationCard" />
    <InformationCard name="value" question="How will this product add value or address needs? Which needs is the product addressing?" answer1="Usefulness: Teachers are extremely busy and have no time to do their content preparation. Using Dynos LMS, teachers expect to save time in preparing their class content, grading, and they can monitor students’ performances easily." answer2="Interest: Teachers get real-time feedback on how well students are engaged with their content." answer3="Importance: Dynos helps teachers to cut down their grading work with auto-grading of all the quizzes with multiple choice and fill in the blanks type of questions." mdxType="InformationCard" />
    <InformationCard name="cost" question="What do users have to give up to use this product? How can we minimize this cost?" answer1="Teachers are required to follow Dynos digital curriculum for all their core subjects without needing to pick and choose various other publishers' content." answer2="This saves time for teachers and brings uniformity across the board." mdxType="InformationCard" />
    <BenefitsHeadingButton heading="Ready to take your teaching to the next level?" button="Login" mdxType="BenefitsHeadingButton" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      